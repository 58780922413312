.searchboxfl {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.searchboxfl .searchboxItem {
  margin-right: 0 !important;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.statistics {
  margin-bottom: 10px;
}
.statistics > span + span {
  margin-left: 10px;
}
